<script lang="ts" setup>
import { useWindowScroll } from '@vueuse/core'

const { y } = useWindowScroll()
const isShow = computed(() => {
  return y.value > window.innerHeight && !client.isMobile
})

function toTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}
</script>

<template>
  <ClientOnly>
    <i v-if="isShow" class="i-local:totop to-top to-top-wrapper" @click="toTop" data-test-id="a58f" />
  </ClientOnly>
</template>

<style lang="scss" scoped>
.to-top {
  position: fixed;
  z-index: 5;
  right: 25px;
  bottom: calc(15% - 80px);

  @media bp-lt-laptop {
    display: none;
  }
}

.to-top-wrapper {
  cursor: pointer;
  font-size: 90px;

  &:active {
    transform: scale(0.9);
  }
}
</style>
